import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { DateTime, Duration } from 'luxon';

import { Day } from 'model';
import './DatePicker.scss';
import DatePickerSpinner from './DatePickerSpinner';
import DatePickerMonthSelect from './DatePickerMonthSelect';
import { useMarketplace, useMasterData } from 'hooks';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import store from 'store';
import useNonInitialEffect from '@versiondos/hooks';

interface DatePickerProps {
    value: Day;
    loading: boolean;
    disabled: string[];
    currentDate: DateTime;
    //
    onChange(selectedDate: Day): void;
}

const DatePicker: React.FunctionComponent<DatePickerProps> = props => {
    const isMobile = matchMedia('(max-width: 900px)').matches;

    const marketplace = useMarketplace();
    const masterData = useMasterData();

    const fromOffsetSeconds =
        masterData.olbFromLimits.find(limit => limit.id === marketplace.fromTimeLimitId)
            ?.offsetSeconds ?? 0;
    const toOffsetSeconds =
        masterData.olbToLimits.find(limit => limit.id === marketplace.toTimeLimitId)
            ?.offsetSeconds ?? null;

    // Constants values
    const currentDate = props.currentDate;
    const enabledFromBooking = currentDate.plus({ seconds: fromOffsetSeconds }).startOf('day');
    const enabledToBooking =
        toOffsetSeconds === null
            ? null
            : currentDate.plus({ seconds: toOffsetSeconds }).endOf('day');

    // DatePicker dates for spinner
    const [actualDate, setActualDate] = useState<DateTime>(enabledFromBooking.startOf('week'));
    const [actualMonth, setActualMonth] = useState<string>(actualDate.toFormat('LLLL'));

    const [yearNumber, setYearNumber] = useState<number>(actualDate.year);
    const [weekNumber, setWeekNumber] = useState<number>(actualDate.weekNumber);

    const [selectMonthValue, setMonthValue] = useState<string>(enabledFromBooking.toFormat('LL/y'));

    const [, setFirstWeekCurrentYear] = useState<number>(1);
    const [lastWeekCurrentYear, setLastWeekCurrentYear] = useState<number>(
        actualDate.weeksInWeekYear
    );

    // DatePicker values for rendering
    const [dayRange, setDayRange] = useState<Day[]>([]);
    const [onCurrentWeek, setCurrentWeek] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Day>({
        object: enabledFromBooking,
        ISODate: enabledFromBooking.toISODate(),
        dayLabel: enabledFromBooking.toFormat('ccc', { locale: 'en' }),
        dayNumber: enabledFromBooking.toFormat('dd', { locale: 'en' })
    });

    const [disabled, setDisabled] = useState(props.disabled);

    useNonInitialEffect(() => {
        if (!isMobile) return;

        const [month, year] = selectMonthValue.split('/');

        const dayNumber = parseInt(month) === enabledFromBooking.month ? enabledFromBooking.day : 1;

        const newDate = DateTime.now()
            .setZone(marketplace.timeZone)
            .set({
                year: parseInt(year),
                month: parseInt(month),
                day: dayNumber
            });

        if (props.value.object.hasSame(newDate, 'month')) {
            return;
        }

        const datePickerSpinner = document.querySelector('.DatePicker-spin');

        datePickerSpinner && (datePickerSpinner.scrollLeft = 0);

        setActualDate(newDate);
        setYearNumber(parseInt(year));
        setActualMonth(newDate.toFormat('LLLL'));

        const newSelectedDate = {
            object: newDate,
            ISODate: newDate.toISODate(),
            dayLabel: newDate.toFormat('ccc', { locale: 'en' }),
            dayNumber: newDate.toFormat('dd', { locale: 'en' })
        };

        // setSelectedDate(newSelectedDate);
        onDateSelected(newSelectedDate);
    }, [selectMonthValue]);

    useEffect(() => {
        calculateDayRange();
    }, [actualDate]);

    useEffect(() => {
        onDateSelected(props.value);
    }, [props.value]);

    const getLastWeekOfYear = (year: number) => {
        const weeksInYear = DateTime.fromObject({ year }).weeksInWeekYear;
        const isWeekNumberValid = DateTime.fromObject({
            weekYear: year,
            weekNumber: weeksInYear
        }).isValid;

        return isWeekNumberValid ? weeksInYear : weeksInYear - 1;
    };

    const changeYear = (action: 'prev' | 'next') => {
        const newYearNumber = action === 'next' ? yearNumber + 1 : yearNumber - 1;

        const firstWeek = 1;
        const lastWeek = getLastWeekOfYear(newYearNumber);

        setDateRange(action, newYearNumber, firstWeek, lastWeek);
    };

    const setDateRange = (
        action: 'prev' | 'next',
        year: number,
        firstWeek: number,
        lastWeek: number
    ) => {
        const toNext = (action = 'next');

        const newDate = DateTime.now()
            .setZone(marketplace.timeZone)
            .set({
                weekYear: year,
                weekNumber: toNext ? firstWeek : lastWeek
            });

        setYearNumber(year);
        setWeekNumber(toNext ? firstWeek : lastWeek);
        setFirstWeekCurrentYear(firstWeek);
        setLastWeekCurrentYear(lastWeek);
        setActualDate(newDate);
        setActualMonth(newDate.toFormat('LLLL'));
    };

    const calculateDayRange = (): void => {
        const arrDates = [];
        let date = actualDate.startOf(isMobile ? 'month' : 'week');
        const endRange = actualDate.endOf(isMobile ? 'month' : 'week');

        setCurrentWeek(date.hasSame(currentDate, 'week'));

        while (date < endRange) {
            if (!isMobile || (isMobile && date >= enabledFromBooking.startOf('day'))) {
                arrDates.push({
                    object: date,
                    ISODate: date.toISODate(),
                    dayLabel: date.toFormat('ccc', { locale: 'en' }),
                    dayNumber: date.toFormat('dd', { locale: 'en' })
                });
            }

            date = date.plus(Duration.fromObject({ days: 1 }));
        }

        const offRangeDates = arrDates
            .filter(
                rangeDate =>
                    rangeDate.object.startOf('day') < enabledFromBooking ||
                    (enabledToBooking !== null && rangeDate.object.endOf('day') > enabledToBooking)
            )
            .map(rangeDate => rangeDate.ISODate);

        setDisabled([...props.disabled, ...offRangeDates]);

        setDayRange(arrDates);
    };

    const changeWeek = (action: 'prev' | 'next') => {
        if (action === 'prev') {
            amplitude.track(AMPLITUDE.CTA_PREVIOUS_WEEK, {
                marketplace: store.getState().marketplace.marketplace.name
            });
        }

        if (action === 'next') {
            amplitude.track(AMPLITUDE.CTA_NEXT_WEEK, {
                marketplace: store.getState().marketplace.marketplace.name
            });
        }

        if (weekNumber === 1 && action === 'prev') {
            changeYear(action);
            return;
        }

        if (weekNumber === lastWeekCurrentYear && action === 'next') {
            changeYear(action);
            return;
        }

        const newWeekNumber = weekNumber + (action === 'prev' ? -1 : 1);

        const newDate = DateTime.now().setZone(marketplace.timeZone).set({
            weekYear: yearNumber,
            weekNumber: newWeekNumber
        });

        setActualDate(newDate);
        setWeekNumber(newWeekNumber);
        setActualMonth(newDate.toFormat('LLLL'));
    };

    const onDateSelected = (date: Day, inputId?: string) => {
        if (inputId) {
            const input = document.querySelector<HTMLInputElement>(inputId);
            input && (input.checked = true);
        }

        setWeekNumber(date.object.weekNumber);
        setActualMonth(date.object.toFormat('LLLL'));
        setMonthValue(date.object.toFormat('LL/y'));
        setActualDate(date.object);
        setSelectedDate(date);
        props.onChange(date);
    };

    const monthChangeHandler = (value: string) => {
        setMonthValue(value);
    };

    //

    return (
        <Box className="DatePicker">
            {isMobile && (
                <Box sx={{ mb: 2 }}>
                    <DatePickerMonthSelect value={selectMonthValue} onChange={monthChangeHandler} />
                </Box>
            )}

            <Box className="DatePicker-label">
                {actualMonth} {yearNumber}
                {onCurrentWeek && <Box className="DatePicker-badge">This week</Box>}
            </Box>

            <DatePickerSpinner
                range={dayRange}
                value={selectedDate}
                isMobile={isMobile}
                loading={props.loading}
                disabled={disabled}
                currentDate={props.currentDate}
                onCurrentWeek={onCurrentWeek}
                controlAction={changeWeek}
                onChange={onDateSelected}
            />
        </Box>
    );
};

export default DatePicker;
