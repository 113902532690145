import React, { useMemo } from 'react';

import { Box, Grid, SxProps } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapPin } from '@fortawesome/pro-light-svg-icons';

import { Marketplace, MarketplaceType } from 'model';
import './MarketplaceInfoReverse.scss';

interface MarketplaceInfoReverseProps {
    sx?: SxProps;
    marketplace: Marketplace;
}

const MarketplaceInfoReverse: React.FunctionComponent<MarketplaceInfoReverseProps> = props => {
    const phone = useMemo(() => {
        const marketplacePhone = props.marketplace.phone;

        if (!marketplacePhone) return '';

        return `(${marketplacePhone.slice(0, 3)}) ${marketplacePhone.slice(
            3,
            6
        )}-${marketplacePhone.slice(6, 10)}`;
    }, [props.marketplace?.phone]);

    return (
        <Box sx={props.sx}>
            <Grid container alignItems="center" spacing={2} className="MarketplaceInfoReverse">
                <Grid item xs={12} md={8} className="MarketplaceInfoReverse-meta">
                    <div className="MarketplaceInfoReverse-title">{props.marketplace.name}</div>
                    <p>
                        <FontAwesomeIcon icon={faPhone} />{' '}
                        <a href={`tel:+1${props.marketplace.phone}`}>{phone}</a>
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faMapPin} />{' '}
                        {props.marketplace.address && (
                            <>
                                {[MarketplaceType.LOCAL_BUSINESS, MarketplaceType.ATHOME].includes(
                                    props.marketplace.businessType
                                )
                                    ? props.marketplace.address[0].addressLineOne +
                                      (props.marketplace.address[0].addressLineTwo
                                          ? ', ' + props.marketplace.address[0].addressLineTwo
                                          : '') +
                                      ', ' +
                                      props.marketplace.address[0].city +
                                      ', ' +
                                      props.marketplace.address[0].state +
                                      ', ' +
                                      props.marketplace.address[0].zipcode
                                    : props.marketplace.address[0].city +
                                      ', ' +
                                      props.marketplace.address[0].state}
                            </>
                        )}
                    </p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img
                        src={props.marketplace.logo}
                        alt={props.marketplace.name}
                        className="MarketplaceInfoReverse-logo"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default MarketplaceInfoReverse;
