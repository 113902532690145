import React, { useRef } from 'react';
import { Box, Checkbox, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PetVaccine } from 'model';
import VaccineFileButton from 'components/Pet/Vaccine/VaccineFileButton';
import VaccineExpirationDateField from 'components/Pet/Vaccine/VaccineExpirationDateField';
import { Moment } from 'moment-timezone';

export interface VaccineOptionProps {
    id: number;
    checked: boolean;
    vaccine: PetVaccine;
    onChange: (vaccine: PetVaccine, isChecked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'gap': 20,
            'display': 'flex',

            '&:not(:last-child)': {
                borderBottom: 'solid 1px #D4D4D4'
            },

            [theme.breakpoints.up('md')]: {
                height: 95,
                alignItems: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '16px 0px',
                flexDirection: 'column'
            }
        },
        label: {
            gap: 8,
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 500,
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%'
        },
        checkbox: {
            'padding': 0,
            'color': '#000',

            '& svg': {
                width: 20,
                fill: '#000'
            }
        },
        actions: {
            flex: 1,
            gap: 24,
            overflow: 'hidden',
            display: 'inline-flex',
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
                gap: 14,
                alignItems: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 8,
                flexDirection: 'column'
            }
        },
        button: {
            'gap': 8,
            'height': 45,
            'fontSize': 16,
            'borderRadius': 30,
            'cursor': 'pointer',
            'padding': '0px 20px',
            'alignItems': 'center',
            'display': 'inline-flex',
            'justifyContent': 'center',
            'border': 'solid 1px #000',
            'backgroundColor': '#fff',

            [theme.breakpoints.only('md')]: {
                fontSize: 15,
                padding: '0px 12px'
            },

            '& svg': {
                'marginTop': -2,

                '&:last-child': {
                    fontSize: 14,
                    paddingTop: 3
                }
            },
            '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        },
        expirationDateButton: {},
        fileButton: {
            [theme.breakpoints.up('lg')]: {
                maxWidth: 140,
                flex: '0 0 140px'
            }
        },
        info: {
            display: 'flex',
            flexDirection: 'column'
        },
        status: {
            paddingLeft: '27px',
            width: '100%'
        },
        badge: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '25px',
            padding: '3px 14px',
            borderRadius: '23px',
            display: 'inline-block',
            verticalAlign: 'middle',
            color: 'var(--error-color)',
            backgroundColor: 'var(--error-color-light)',
        }
    })
);

const VaccineOption: React.FC<VaccineOptionProps> = props => {
    const classes = useStyles();

    const inputRef = useRef<HTMLInputElement>(null);

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(
            {
                ...props.vaccine,
                recordFileToUpload: event.target.files?.[0]
            },
            props.checked
        );
    };

    const removeFileHandler = () => {
        props.onChange(
            {
                ...props.vaccine,
                recordFileToUpload: undefined
            },
            props.checked
        );
    };

    const openFileDialogHandler = () => {
        inputRef.current?.click();
    };

    const expirationDateChangeHandler = (value: Moment) => {
        props.onChange(
            {
                ...props.vaccine,
                expiration: value
            },
            props.checked
        );
    };

    const toggleCheckHandler = () => {
        props.onChange(props.vaccine, !props.checked);
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.info}>
                <div className={classes.label} onClick={toggleCheckHandler}>
                    <Checkbox
                        checked={props.checked}
                        className={classes.checkbox}
                        id={`vaccine-${props.id}`}
                    />
                    {props.vaccine.type?.name}
                </div>
                { props.vaccine.status === 'Expired' ?
                    <div className={classes.status}>
                        <span className={classes.badge}>
                            Expired
                        </span>
                    </div> : ''
                }
            </Box>

            <input
                hidden
                type="file"
                ref={inputRef}
                id={`vaccine-file-${props.id}`}
                onChange={fileChangeHandler}
            />

            {props.checked && (
                <Box className={classes.actions}>
                    <VaccineExpirationDateField
                        id={`vaccine-date-${props.id}`}
                        buttonId={`vaccine-add-date-${props.id}`}
                        defaultValue={props.vaccine.expiration?.format('YYYY-MM-DD')}
                        onChange={expirationDateChangeHandler}
                    />

                    <VaccineFileButton
                        id={`vaccine-upload-${props.id}`}
                        file={props.vaccine.recordFileToUpload}
                        className={clsx(classes.button, classes.fileButton)}
                        onClick={openFileDialogHandler}
                        onRemove={removeFileHandler}
                    />
                </Box>
            )}
        </Box>
    );
};

export default VaccineOption;
