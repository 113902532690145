import React, { useMemo } from 'react';

import { Box, Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapPin } from '@fortawesome/pro-light-svg-icons';

import './MarketplaceInfo.scss';
import { Marketplace, MarketplaceType } from 'model';

interface MarketplaceInfoProps {
    sx?: object;
    hideLogo?: boolean;
    marketplace: Marketplace;
}

const MarketplaceInfo: React.FunctionComponent<MarketplaceInfoProps> = props => {
    const phone = useMemo(() => {
        const marketplacePhone = props.marketplace.phone;

        if (!marketplacePhone) return '';

        return `(${marketplacePhone.slice(0, 3)}) ${marketplacePhone.slice(
            3,
            6
        )}-${marketplacePhone.slice(6, 10)}`;
    }, [props.marketplace?.phone]);

    return (
        <Box sx={props.sx}>
            <Grid container alignItems="center" spacing={2} className="MarketplaceInfo">
                {!props.hideLogo && (
                    <Grid item xs={12} md={3}>
                        <img
                            src={props.marketplace.logo}
                            alt={props.marketplace.name}
                            className="MarketplaceInfo-logo"
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={9} className="MarketplaceInfo-meta">
                    <div className="MarketplaceInfo-title">{props.marketplace.name}</div>
                    <p>
                        <a href={`tel:+1${props.marketplace.phone}`}>
                            <FontAwesomeIcon icon={faPhone} /> {phone}
                        </a>
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faMapPin} />{' '}
                        {props.marketplace.address && (
                            <>
                                {[MarketplaceType.LOCAL_BUSINESS, MarketplaceType.ATHOME].includes(
                                    props.marketplace.businessType
                                )
                                    ? props.marketplace.address[0].addressLineOne +
                                      (props.marketplace.address[0].addressLineTwo
                                          ? ', ' + props.marketplace.address[0].addressLineTwo
                                          : '') +
                                      ', ' +
                                      props.marketplace.address[0].city +
                                      ', ' +
                                      props.marketplace.address[0].state +
                                      ', ' +
                                      props.marketplace.address[0].zipcode
                                    : props.marketplace.address[0].city +
                                      ', ' +
                                      props.marketplace.address[0].state}
                            </>
                        )}
                    </p>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MarketplaceInfo;
