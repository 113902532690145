import store from 'store';
import { Pet, PetAge, PetAges, PetHairLengths, PetSize, PetSizes, PetVaccine } from 'model';
import {
    CatDetailsDto,
    DogDetailsDto,
    ExoticDetailsDto,
    PetAgeDto,
    PetDataDtos,
    PetSaveDataDtos,
    PetVaccineDto
} from './PetDataDtos';
import { PetSizeDto } from 'actions/masterData/MasterDataDtos';
import { parseJSON } from 'utils';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';


export const convertPetsData = (petDataDto: PetDataDtos[]): Pet[] => {
    return petDataDto.map(item => convertPetData(item));
};

export const convertPetData = (petDataDto: PetDataDtos): Pet => {
    const storeState = store.getState();
    const masterData = storeState.masterdata.data;
    const petData = storeState.pets;

    return {
        id: petDataDto.id,
        image: petDataDto.image_url,
        age: petDataDto.age,
        breed: petDataDto.pet_breed,
        gender: petDataDto.pet_gender,
        name: petDataDto.name,
        birthdate: petDataDto.birthdate,
        hairLength: petData.hairLengths[
            petDataDto.pet_type as keyof typeof masterData.petHairColors
        ]?.find(opt => opt.id === petDataDto.pet_hair_length),
        hairColor: masterData.petHairColors[
            petDataDto.pet_type as keyof typeof masterData.petHairColors
        ].find(opt => opt.id === petDataDto.pet_hair_color)!,
        type: masterData.petTypes.find(pt => pt.id === petDataDto.pet_type)!,
        size: petDataDto.pet_size,
        weight: petDataDto.pet_weight === null ? undefined : petDataDto.pet_weight,
        uuid: petDataDto.uuid || uuid(),
        active: petDataDto.active,
        vaccines:
            petDataDto.pet_vaccine_expirations?.map(vaccine => convertPetVaccine(vaccine)) || []
    };
};

export const convertToSavePetDto = (pet: Pet, marketplaceId: number): PetSaveDataDtos => {
    return {
        id: pet.id,
        uuid: pet.uuid,
        marketplace_id: marketplaceId,
        name: pet.name,
        pet_type: pet.type?.id,
        pet_size: pet.size,
        pet_weight: pet.weight,
        pet_breed: pet.breed,
        pet_gender: pet.gender,
        pet_hair_color: pet.hairColor?.id,
        pet_hair_length: pet.hairLength?.id,
        birthdate: pet.birthdate,
        age: pet.age,
        active: pet.active,
        image: pet.imageRemoved || pet.image === '' ? new Blob() : pet.imageFileToUpload,
        pet_vaccine_expirations_attributes: pet.vaccines.filter(vaccine => vaccine.id === undefined).map(vaccine => convertToPetVaccineDto(vaccine, pet.id!))
    };
};

export const convertPetSizes = (
    catDetails: string,
    dogDetails: string,
    exoticDetails: string
): PetSizes => {
    const defaultValue = { sizes: [], ages: [] };

    const cats: CatDetailsDto = parseJSON(catDetails, defaultValue);
    const dogs: DogDetailsDto = parseJSON(dogDetails, defaultValue);
    const exotics: ExoticDetailsDto = parseJSON(exoticDetails, defaultValue);

    return {
        cats:
            cats !== undefined && (cats.enabled ?? true)
                ? cats.sizes?.map((size, i) => convertPetSize(size, i))
                : [],
        dogs:
            dogs !== undefined && (dogs.enabled ?? true)
                ? dogs.sizes?.map((size, i) => convertPetSize(size, i))
                : [],
        exotics:
            exotics !== undefined && (exotics.enabled ?? true)
                ? exotics.sizes?.map((size, i) => convertPetSize(size, i))
                : []
    };
};

export const convertPetHairLengths = (
    catDetails: string,
    dogDetails: string,
    exoticDetails: string
): PetHairLengths => {
    const defaultValue = { sizes: [], ages: [] };

    const cats: CatDetailsDto = parseJSON(catDetails, defaultValue);
    const dogs: DogDetailsDto = parseJSON(dogDetails, defaultValue);
    const exotics: ExoticDetailsDto = parseJSON(exoticDetails, defaultValue);

    return {
        cats: cats !== undefined ? cats.hairLengths : [],
        dogs: dogs !== undefined ? dogs.hairLengths : [],
        exotics: exotics !== undefined ? exotics.hairLengths : []
    };
};

export const convertPetAges = (
    catDetails: string,
    dogDetails: string,
    exoticDetails: string
): PetAges => {
    const defaultValue = { sizes: [], ages: [] };

    const cats: CatDetailsDto = parseJSON(catDetails, defaultValue);
    const dogs: DogDetailsDto = parseJSON(dogDetails, defaultValue);
    const exotics: ExoticDetailsDto = parseJSON(exoticDetails, defaultValue);

    return {
        cats:
            cats !== undefined && (cats.enabled ?? true)
                ? cats.ages?.map(age => convertPetAge(age))
                : [],
        dogs:
            dogs !== undefined && (dogs.enabled ?? true)
                ? dogs.ages?.map(age => convertPetAge(age))
                : [],
        exotics:
            exotics !== undefined && (exotics.enabled ?? true)
                ? exotics.ages?.map(age => convertPetAge(age))
                : []
    };
};

export const convertPetSize = (size: PetSizeDto, order: number): PetSize => {
    return {
        order,
        element: size
    };
};

export const convertPetAge = (age: PetAgeDto): PetAge => age;

export const convertPetVaccine = (vaccineDto: PetVaccineDto): PetVaccine => ({
    id: vaccineDto.id,
    uuid: vaccineDto.uuid,
    expiration: moment(vaccineDto.expiration_date),
    recordUrl: vaccineDto.file_url || undefined,
    status: vaccineDto.status as string,
    type: {
        id: vaccineDto.vaccine_id,
        name: vaccineDto.vaccine_id.charAt(0).toUpperCase() + vaccineDto.vaccine_id.slice(1).replace(/[-_]/g, ' ')
    },
    recordFileToUpload: vaccineDto.file_url ? new File([vaccineDto.file_url!], vaccineDto.file_name!) : undefined
});

export const convertToPetVaccineDto = (vaccine: PetVaccine, petId: number): PetVaccineDto => ({
    id: vaccine.id,
    pet_id: petId,
    uuid: vaccine.uuid,
    vaccine_id: `${vaccine.type?.id}`,
    expiration_date: vaccine.expiration?.toISOString(),
    file: vaccine.recordFileToUpload
});
